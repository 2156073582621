import { Button/*, Modal*/ } from "antd";
// import { useState } from "react";
import { useNavigate } from "react-router-dom";
import freestyle from "../assets/image/freestyle_logo.png";
import abbott from "../assets/image/abbott_logo.png";
// import road from "../assets/image/Group 5.png";
// import tick1 from "../assets/image/tick1.png";
import linkIcon from "../assets/image/link.png";
import "../App.css";

const End = () => {

  const navigate = useNavigate();
  // const [showModal, setShowModal] = useState(true);

  return (
    <div className="endpage">
      <div className="center-logo">
        <img src={abbott} alt="" style={{ maxWidth: "100%" }}></img>
        <img src={freestyle} alt="" style={{ maxWidth: "100%" }}></img>
      </div>
      <div style={{ paddingLeft: "5%", paddingRight: "5%", textAlign: "center", color: "#131685", marginTop: "3%" }}>
        <p>Thank you for registering for our FreeStyle Group Education Program. Please click this link below to register for the Group Education session and get a free sensor. </p>
      </div>
      <div style={{ paddingLeft: "5%", paddingRight: "5%" }}>
        <p style={{ color: "#131685", marginBottom: "0px" }}><b>LINK</b></p>
        <small><u>English</u></small>
        <div className="pb-1" style={{ display: "flex" }}>
          <div style={{ paddingRight: "2%" }}>
            <img src={linkIcon} alt="" />
          </div>
          <a href="https://app.livestorm.co/emerging-asia-abbott-diabetes-care/my-education-session-on-demand-english?type=detailed" style={{ color: "#131685" }}>https://app.livestorm.co/emerging-asia-abbott-diabetes-care/my-education-session-on-demand-english?type=detailed</a>
        </div>
        <small><u>Bahasa Malaysia</u></small>
        <div style={{ display: "flex" }}>
          <div style={{ paddingRight: "2%" }}>
            <img src={linkIcon} alt="" />
          </div>
          <a href="https://app.livestorm.co/emerging-asia-abbott-diabetes-care/my-education-session-on-demand-bahasa?type=detailed" style={{ color: "#131685" }}>https://app.livestorm.co/emerging-asia-abbott-diabetes-care/my-education-session-on-demand-bahasa?type=detailed</a>
        </div>

        <div style={{ textAlign: "center", color: "#131685", marginTop: "5%" }}>
          <p>Upon completion, please click the button below to redeem free sensor.</p>
          <Button
            className="ok-button-three"
            onClick={() => navigate("/login")}
          >
            Free Sensor Redemption
          </Button>
        </div>
      </div>
      {/* <div className="journey-header">This is your journey’s progress.</div>
      <div style={{ padding: "5%" }}>
        <Steps current={1}>
          <Step title="Sign Up" className="step-original"/>
          <Step title="Get Confirmation Email" />
          <Step title="Attend Group Start" />
          <Step title="Upload Receipt of Starter Kit Purchase" />
          <Step title="Receive 1 Sensor FOC" />
        </Steps>
        <img src={road} alt="" style={{ width: "100%" }}></img>
      </div> */}
      {/* <Modal
        open={showModal}
        centered
        header={null}
        footer={null}
        closable={null}
      >
        <div style={{ textAlign: "center" }}>
          <img
            src={tick1}
            style={{
              marginBottom: "5vh",
              width: "100px",
              height: "100px",
            }}
            alt=""
          ></img>
          <h1 style={{ fontWeight: "bold" }}>Success!</h1>
          <div
            style={{
              marginBottom: "3vh",
            }}
          >
            Thank you for registering for our FreeStyle Group Education Program. Please click this link below to register for the Group Education session and get a free sensor. <a href="https://app.livestorm.co/emerging-asia-abbott-diabetes-care/my-freestyle-libre-group-education?type=detailed">https://app.livestorm.co/emerging-asia-abbott-diabetes-care/my-freestyle-libre-group-education?type=detailed</a>
          </div>
          <Button
            className="ok-button-two"
            style={{ width: "50%" }}
            onClick={() => setShowModal(false)}
          >
            OK
          </Button>
        </div>
      </Modal> */}
    </div>
  );
};

export default End;
