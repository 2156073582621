import abbottLogo from "../assets/image/abbott_logo.png";
import freestyle from "../assets/image/freestyle_logo.png";

const Welcome = () => {
  return (
    <div className="flashscreen">
      <div className="center-all">
        <img src={abbottLogo} alt="" />
        <img src={freestyle} alt="" />
      </div>
    </div>
  );
};

export default Welcome;
