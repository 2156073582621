import abbottLogo from "../assets/image/abbott_logo.png";
import freestyle from "../assets/image/freestyle_logo.png";

const RedemptionComplete = () => {

    return (
        <div className="flashscreen">
            <div className="center-logo">
                <img src={abbottLogo} alt="" className="img-fluid"></img>
                <img src={freestyle} alt="" className="img-fluid"></img>
            </div>

            <div className="text-center mt-5">
                <p style={{ fontSize: "24px", fontWeight: 390 }}>You have successfully redeemed the free sensor.</p>
                <p style={{ fontSize: "24px", fontWeight: 390 }}>Thank you for your participation.</p>
            </div>

        </div>
    );
};

export default RedemptionComplete;
