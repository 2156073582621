import abbottLogo from "../assets/image/abbott_logo.png";
import freestyle from "../assets/image/freestyle_logo.png";

const PhysicalComplete = () => {

    return (
        <div className="flashscreen">
            <div className="center-logo">
                <img src={abbottLogo} alt="" className="img-fluid"></img>
                <img src={freestyle} alt="" className="img-fluid"></img>
            </div>

            <div className="text-center mt-5 px-3" style={{ fontSize: "24px", fontWeight: 390 }}>
                <p>Thank you for your interest in Abbott Diabetic Care Education Programme.</p>
                <p>Each customer may only redeem up to one (1) free sensor and you have successfully collected during your previous visit.</p>
                <p>You are not entitled for one Free Sensor this round.</p>
                <p>Thank you for your participation.</p>
            </div>

        </div>
    );
};

export default PhysicalComplete;
