import React, { Suspense } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import "antd/dist/antd.css";

// import Welcome from "./pages/user/welcome";
import Welcome from "./pages/welcome";
import GroupEducation from "./pages/GroupEducation";
import FirstPage from "./pages/SelectPage";
import End from "./pages/EndPage";
import Upload from "./pages/UploadPage";
import Login from "./pages/LoginPage";
import Otp from "./pages/Otp";
import StartRedeem from "./pages/startRedeem";
import Redemption from "./pages/Redemption";
import RedemptionComplete from "./pages/RedemptionComplete";
import PhysicalRegister from "./pages/PhysicalRegister";
import PhysicalComplete from "./pages/PhysicalComplete";

function App() {
  return (
    <div
      className="App prevent-select "
      style={{
        maxWidth: "500px",
        margin: "0 auto",
        position: "relative",
        height: "100vh",
      }}
    >
      <Suspense fallback={<Welcome />}>
        <BrowserRouter>
          <Routes>
            {/* <Route exact path="/" element={<Welcome />} /> */}

            <Route exact path="/welcome" element={<FirstPage />} />
            <Route exact path="/login" element={<Login />} />
            <Route exact path="/otp" element={<Otp />} />
            <Route exact path="/register" element={<GroupEducation />} />
            <Route exact path="/physicalRegister" element={<PhysicalRegister />} />
            <Route exact path="/journey" element={<End />} />
            <Route exact path="/upload" element={<Upload />} />
            <Route exact path="/startRedeem" element={<StartRedeem />} />
            <Route exact path="/redemption" element={<Redemption />} />
            <Route exact path="/redemptionComplete" element={<RedemptionComplete />} />
            <Route exact path="/physicalComplete" element={<PhysicalComplete />} />

            <Route path="*" element={<Navigate to="/welcome" />} />
          </Routes>
        </BrowserRouter>
      </Suspense>
    </div>
  );
}

export default App;
