import { createStore, compose, applyMiddleware } from "redux";
import userReducer from "../reducers/userReducer";
import thunk from "redux-thunk";

const middlewares = [thunk];

const store = createStore(
	userReducer,
	compose(
		applyMiddleware(...middlewares)
		//,window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
	)
);

export default store;
