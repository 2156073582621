const initState = {
    token: localStorage.getItem("freestyle-adc-user"),
    user: null,
    storecode: localStorage.getItem("freestyle-adc-storecode")
};

const userReducer = (state = initState, action) => {
    switch (action.type) {
        case "USER_LOGIN":
            localStorage.setItem("freestyle-adc-user", action.payload.token);
            return {
                ...state,
                token: action.payload.token,
                user: action.payload.user
            };
        case "SAVE_STORECODE":
            localStorage.setItem("freestyle-adc-storecode", action.payload);
            return {
                ...state,
                storecode: action.payload
            };
        case "UPDATE_ADDRESS":
            return {
                ...state,
                user: {
                    ...state.user,
                    address: action.payload
                }
            };
        case "UPDATE_TRANSACTION":
            return {
                ...state,
                user: {
                    ...state.user,
                    transactions: [...state.user.transactions, action.payload]
                }
            };
        case "UPDATE_REDEEM":
            return {
                ...state,
                user: {
                    ...state.user,
                    redeem: action.payload
                }
            };
        default:
            return state;
    }
};

export default userReducer;
