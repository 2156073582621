import { Button } from "antd";
import abbottLogo from "../assets/image/abbott_logo.png";
import freestyle from "../assets/image/freestyle_logo.png";
import { useNavigate } from "react-router-dom";

const StartRedeem = () => {

    const navigate = useNavigate();

    return (
        <div className="flashscreen">
            <div className="center-logo">
                <img src={abbottLogo} alt="" className="img-fluid"></img>
                <img src={freestyle} alt="" className="img-fluid"></img>
            </div>

            <div style={{ padding: "5%", textAlign: "center", color: "#131685", marginTop: "3%" }}>
                <p>Thank you for registering for FreeStyle Libre Education Program.</p>
            </div>
            <div style={{ padding: "5%", textAlign: "center", color: "#131685" }}>
                <p>Continue to submit your receipt now to get your free sensor once you have received the education from our HCP!</p>
            </div>

            <div className="text-center">
                <Button style={{ width: "80%" }} className="ok-button-two" onClick={() => navigate('/upload')}>
                    FREE SENSOR REDEMPTION
                </Button>
            </div>
        </div>
    );
};

export default StartRedeem;
