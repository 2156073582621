import { Button, Input, Form, Modal } from "antd";
import abbottLogo from "../assets/image/abbott_logo.png";
import freestyle from "../assets/image/freestyle_logo.png";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import axios from "axios";
import login from "../assets/image/login.png";
import { connect } from 'react-redux';

const LoginPage = ({ storecode, userLogin }) => {

  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [number, setNumber] = useState("");
  const [errorModal, setErrorModal] = useState(false);
  const [error, setError] = useState("");

  const isNumber = (e) => {
    setNumber(e.target.value);

    let regNumber = /^(01)[0-46-9]([0-9]){7,8}$/;

    if (!regNumber.test(e.target.value)) {
      setError(
        'Invalid phone number format. Supported phone number format is "0123456789"'
      );
    } else {
      setError("");
    }
    if (!e.target.value) {
      setError("");
    }
  };

  const onCheckNumber = () => {
    if (!error) {
      setLoading(true);
      axios
        .post("/api/auth/checkUser", { number })
        .then((data) => {
          if (storecode && storecode.includes("FSLPhy")) {
            axios.post("/api/auth/sendOtp", { number })
              .then(res => {
                setLoading(false);
                navigate("/otp", { state: { number } });
              })
              .catch(err => {
                setError(err.response.data.error);
                setLoading(false)
              })
          } else if (data.data.identity === "NEW") {
            setLoading(false);
            setErrorModal(true);
          } else if (data.data.identity === "EXIST") {
            setLoading(false);
            userLogin({ user: data.data.user, token: data.data.token })
            navigate("/upload");
          }
        })
        .catch((err) => {
          setLoading(false);
          setError(err.response.data.error);
        });
    }
  };

  return (
    <div className="flashscreen">
      {" "}
      <Form onFinish={onCheckNumber}>
        <div className="login-center">
          <div>
            <img src={abbottLogo} alt="" />
            <img src={freestyle} alt="" />
          </div>
          <div className="login-container-left">
            <div className="question-tiitle"><b>Phone Number</b></div>
            <Input
              className="question-input"
              value={number}
              required
              onChange={(e) => isNumber(e)}
              placeholder="Enter Your Phone Number"
            ></Input>
          </div>

          <div className="login-button-container">
            <div className="errormessage-new">{error ? error : null}</div>

            <Button
              style={{ width: "80%" }}
              className="ok-button-two"
              htmlType="submit"
              type="number"
              loading={loading}
            >
              Log In
            </Button>
          </div>
        </div>{" "}
      </Form>
      <Modal
        open={errorModal}
        centered
        header={null}
        footer={null}
        closable={null}
      >
        <div style={{ textAlign: "center" }}>
          <img
            src={login}
            style={{
              marginBottom: "5vh",
              width: "100px",
              height: "100px",
            }}
            alt=""
          ></img>
          <h1 style={{ fontWeight: "bold" }}>
            Oops, you have yet to register in our {storecode && storecode.includes("FSLPhy") ? "Education Session" : "group education"}!
          </h1>
          <p>
            Please register your account and attend {storecode && storecode.includes("FSLPhy") ? "Education Session" : "group education"} before
            submitting the receipt.
          </p>
          <Button
            className="ok-button-two"
            style={{ width: "50%" }}
            onClick={() => navigate("/register")}
          >
            OK
          </Button>
        </div>
      </Modal>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    storecode: state.storecode,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    userLogin: data => dispatch({ type: "USER_LOGIN", payload: data })
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);
