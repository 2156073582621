import { Button } from "antd";
import abbottLogo from "../assets/image/abbott_logo.png";
import freestyle from "../assets/image/freestyle_logo.png";
import sensor from "../assets/image/reader.png";
import { useNavigate, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { connect } from 'react-redux';
import axios from "axios";

const Redemption = ({ user, token, storecode, userLogin/*, userRedeem*/ }) => {

    const navigate = useNavigate();
    const location = useLocation();

    const [loading, setLoading] = useState(false);
    // const [error, setError] = useState("");

    useEffect(() => {
        if (!location.state) {
            navigate("/welcome")
        } else if (!storecode) {
            navigate("/welcome")
        } else if (!storecode.includes("FSLPhy")) {
            navigate("/welcome")
        } else {
            if (!user) {
                if (token) {
                    setLoading(true);
                    axios
                        .post("/api/auth/checkToken", { token })
                        .then((res) => {
                            userLogin({ user: res.data.user, token: res.data.token })
                            if (res.data.user.transactions.length <= 0) {
                                navigate("/welcome")
                            }
                            setLoading(false);
                        })
                        .catch((err) => {
                            navigate("/welcome")
                        });
                } else {
                    navigate("/welcome")
                }
            } else {
                if (user.transactions.length <= 0) {
                    navigate("/welcome")
                }
            }
        }
    }, [])

    // const redeem = () => {
    //     setLoading(true);
    //     axios
    //         .post("/api/trans/redeem", { handphone: user.number })
    //         .then((res) => {
    //             userRedeem(true);
    //             navigate('/redemptionComplete')
    //         })
    //         .catch((err) => {
    //             setLoading(false);
    //             setError(err.response.data.error);
    //         });
    // }

    return (
        <div className="flashscreen">
            <div className="center-logo">
                <img src={abbottLogo} alt="" className="img-fluid"></img>
                <img src={freestyle} alt="" className="img-fluid"></img>
            </div>

            <div className="text-center mt-4">
                <p style={{ fontSize: "32px", fontWeight: "700" }}>Congratulations!</p>
                <p style={{ fontSize: "20px", fontWeight: 390 }}>You're entitled to get a free sensor.</p>
                <p style={{ fontSize: "20px", fontWeight: 390 }}>Please present this to the nurse to redeem your free sensor.</p>
                <img src={sensor} alt="" />
            </div>

            <div className="text-center pb-3">
                {/* <div className="errormessage-new">{error ? error : null}</div> */}
                <Button style={{ width: "80%" }} className="ok-button-two" loading={loading} onClick={() => navigate('/redemptionComplete')}>
                    Complete
                </Button>
            </div>
        </div>
    );
};

const mapStateToProps = state => {
    return {
        storecode: state.storecode,
        user: state.user,
        token: state.token
    };
};

const mapDispatchToProps = dispatch => {
    return {
        userLogin: data => dispatch({ type: "USER_LOGIN", payload: data }),
        //userRedeem: data => dispatch({ type: "UPDATE_REDEEM", payload: data })
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Redemption);
