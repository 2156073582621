import { Button, Form } from "antd";
import abbottLogo from "../assets/image/abbott_logo.png";
import freestyle from "../assets/image/freestyle_logo.png";
import { useNavigate, useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import OtpInput from 'react-otp-input';
import axios from "axios";
import { connect } from 'react-redux';

const Otp = ({ userLogin }) => {

    const navigate = useNavigate();
    const location = useLocation();

    const [loading, setLoading] = useState(false);
    const [number, setNumber] = useState("");
    const [error, setError] = useState("");
    const [otp, setOtp] = useState("");
    const [second, setSecond] = useState(60);

    let timer;

    useEffect(() => {
        if (location.state.number) {
            setNumber(location.state.number);
        } else {
            navigate("/welcome");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        startTimer();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [second])

    const startTimer = () => {
        timer = setTimeout(() => {
            if (second > 0) {
                setSecond(second - 1)
            } else {
                stopTimer()
            }
        }, 1000)
    }

    const stopTimer = () => {
        clearTimeout(timer);
    }

    const checkOtp = () => {
        setError("")
        setLoading(true);
        axios.post("/api/auth/verifyOtp", { number, otp })
            .then(res => {
                userLogin({ user: res.data.user, token: res.data.token })
                if (!res.data.user.email) {
                    navigate("/physicalRegister");
                } else if (res.data.user.transactions.length > 0) {
                    navigate("/physicalComplete");
                } else {
                    navigate("/startRedeem");
                }
            })
            .catch(err => {
                setError(err.response.data.error);
                setLoading(false)
            })
    };

    const resendOtp = () => {
        setLoading(true);
        axios.post("/api/auth/sendOtp", { number })
            .then(res => {
                setLoading(false);
            })
            .catch(err => {
                setError(err.response.data.error);
                setLoading(false)
            })
    }

    return (
        <div className="flashscreen">
            <Form onFinish={checkOtp}>
                <div className="login-center">
                    <div>
                        <img src={abbottLogo} alt="" className="img-fluid" />
                        <img src={freestyle} alt="" className="img-fluid" />
                    </div>

                    <div>
                        <p style={{ fontWeight: 500, fontSize: "24px", fontFamily: "Brandon Grotesque Bold" }}><b>OTP Verification</b></p>
                        <p className="mt-4">Please type verification code sent to <b style={{ fontFamily: "Brandon Grotesque Bold" }}>+6{number}</b></p>
                    </div>

                    <div>
                        <OtpInput
                            value={otp}
                            onChange={(e) => setOtp(e)}
                            numInputs={4}
                            renderInput={(props) => <input {...props} className="mx-2" />}
                            containerStyle={{
                                textAlign: "center",
                                margin: "auto",
                                justifyContent: "center",
                                marginBottom: "5%",
                            }}
                            inputStyle={{
                                width: "70px",
                                height: "70px",
                                fontSize: "30px",
                                fontStyle: "bold",
                                color: "black",
                                // boxShadow: "inset 0px 0px 4px 2px rgba(0, 0, 0, 0.4)",
                                borderRadius: "10px",
                                background: "white",
                                border: "1px solid #FF7A15"
                            }}
                        />
                    </div>

                    <h6><b style={{ fontFamily: "Brandon Grotesque Bold" }}>Didn't get OTP? <u style={{ color: "#FF7A15" }} onClick={() => resendOtp()}>Resend OTP</u></b></h6>

                    <div className="login-button-container">
                        <div className="errormessage-new">{error ? error : null}</div>

                        <Button
                            style={{ width: "80%" }}
                            className="ok-button-two"
                            htmlType="submit"
                            type="number"
                            loading={loading}
                        >
                            Log In
                        </Button>
                    </div>

                    <p>The OTP will be expired in {second} seconds</p>
                </div>
            </Form>
        </div>
    );
};

const mapDispatchToProps = dispatch => {
    return {
        userLogin: data => dispatch({ type: "USER_LOGIN", payload: data })
    };
};

export default connect(null, mapDispatchToProps)(Otp);
