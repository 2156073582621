import React, { useState, useEffect } from "react";
import { Button } from "antd";
import abbottLogo from "../assets/image/abbott_logo.png";
import freestyle from "../assets/image/freestyle_logo.png";
import { useNavigate, useLocation } from "react-router-dom";
import queryString from "query-string";
import axios from "axios";
import { connect } from 'react-redux';

const SelectPage = ({ storecode, saveStorecode }) => {
  const navigate = useNavigate();

  const location = useLocation();
  const { code } = queryString.parse(location.search);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    if (code) {
      setLoading(true);
      axios
        .post("/api/auth/checkStore", { storecode: code })
        .then((res) => {
          setLoading(false);
          saveStorecode(res.data.storecode);
        })
        .catch((err) => {
          setLoading(false);
          setError(err.response.data.error);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const register = () => {
    if (storecode && storecode.includes("FSLPhy")) {
      // physical group education
      navigate("/login")
    } else {
      navigate("/register")
    }
  }

  return (
    <div className="flashscreen">
      <div className="select-center">
        <img src={abbottLogo} alt="" />
        <img src={freestyle} alt="" />
        <div className="yellowbg" style={{ backgroundColor: storecode && storecode.includes("FSLPhy") ? "white" : "#FFD100" }}>
          <div className="first-font">
            {
              storecode && storecode.includes("FSLPhy") ? "Welcome to FreeStyle Libre Education Session! To receive a free sensor, you would need to register a new account, attend our Education Session and purchase a Reader." : "Welcome to FreeStyle Libre Group Education. To receive a free sensor, you would need to register a new account, attend our group education and purchase a starter kit"
            }
          </div>
        </div>
        {storecode && storecode.includes("FSLPhy") ? null : <div className="select-please">Please Select</div>}
        <div className="select-button-container">
          {
            storecode && storecode.includes("FSLPhy") ? null : (
              <Button
                className="ok-button-three"
                loading={loading}
                disabled={error}
                onClick={() => register()}
              >
                New User Registration
              </Button>
            )
          }
          <Button
            className="ok-button-three"
            loading={loading}
            disabled={error}
            onClick={() => navigate("/login")}
          >
            {storecode && storecode.includes("FSLPhy") ? "REDEEM FREE SENSOR NOW" : "Free Sensor Redemption"}
          </Button>
        </div>
        <div className="errormessage-new">
          {error ? error : null}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    storecode: state.storecode,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    saveStorecode: data => dispatch({ type: "SAVE_STORECODE", payload: data })
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SelectPage);
